import styled from "styled-components"

import { fgColorSecondary } from "../../theme"
import { Title as StyledTitle, Text } from "../styled"

export const LogContainer = styled.div`
  z-index: 0;
  position: absolute;
  right: 20px;
  bottom: 50px;
  width: 320px;
`

export const Log = styled.p`
  display: flex;
  padding: 10px;
  color: ${fgColorSecondary};
`

export const LogDate = styled(Text)`
  font-size: 14px;

  &:before {
    content: "[";
  }

  &:after {
    content: "]";
  }
`

export const Title = styled(StyledTitle)`
  padding: 5px 10px;
  font-size: 18px;
  text-align: center;
`

export const TextWithMargin = styled(Text)`
  font-size: 14px;
  margin-left: 20px;
`
