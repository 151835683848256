import React, { useEffect, useRef, useState } from "react"
import countries from "i18n-iso-countries"

import { Log, LogContainer, LogDate, TextWithMargin, Title } from "./log.styled"
import { Container } from "../styled"
import { Loader } from "../loader"
import { useLanguage, useWindowSize } from "../../hooks"
import { MIN_D_WIDTH } from "../../constants"

export function Logger({ log, isLoaded }) {
  const { t, lang } = useLanguage()

  const logRef = useRef(null)
  const [logHeight, setLogHeight] = useState(0)

  const { w } = useWindowSize()

  useEffect(() => {
    if (!logRef.current) {
      return
    }
    const { height } = logRef.current.getBoundingClientRect()
    setLogHeight(height)
  }, [log])

  return (
    w >= MIN_D_WIDTH && (
      <LogContainer
        height={log.length > 0 ? log.length * logHeight : logHeight}
      >
        <Container>
          <Title isWithHeight={!isLoaded}>{t("log.title")}</Title>
          {isLoaded ? (
            log.map(({ key, date, text, country }) => (
              <Log key={key} ref={logRef}>
                <LogDate>{date}</LogDate>
                <TextWithMargin>{`(${countries.getName(country, lang, {
                  select: "official",
                })}): ${text[lang]}`}</TextWithMargin>
              </Log>
            ))
          ) : (
            <Loader />
          )}
        </Container>
      </LogContainer>
    )
  )
}
