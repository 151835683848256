import React from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"

import {
  Footer,
  MainMenu,
  PageContainer,
  TopBar,
  WorldMap,
} from "../components"
import { withTranslationHOC } from "../i18n/with-translation"
import { Logger } from "../components/logger"
import { useRandomInfo } from "../hooks"

function Home() {
  const { t } = useTranslation()
  const { isLoaded, info, log } = useRandomInfo()

  return (
    <PageContainer>
      <Helmet>
        <title>Emergency Analyzer</title>
      </Helmet>
      {!isLoaded && <TopBar />}
      <WorldMap data={info} isLoaded={isLoaded} />
      <Logger log={log} isLoaded={isLoaded} />
      <MainMenu />
      <Footer status={isLoaded ? t("status.done") : t("status.refresh")} />
    </PageContainer>
  )
}

export default withTranslationHOC(Home)
